import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';

import ReactGA from 'react-ga4';

const ForgotPassword = () => {
    ReactGA.send({
        hitType: "pageview",
        page: '/auth/forgot-password',
        title: 'Forgot Password'
    })

    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            await axiosInstance.post('/auth/password/reset/', { email });
            setSuccessMessage('Password reset email sent! Please check your inbox.');
        } catch (error) {
            setErrorMessage('Failed to send password reset email. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-6 text-center">Forgot Password</h2>
                {successMessage && (
                    <div className="bg-green-100 text-green-700 p-2 rounded mb-4">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
                        {errorMessage}
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                        Send Password Reset Email
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;