import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';
import { isAuthenticated } from '../utils/auth';
import {useLocation, useNavigate} from 'react-router-dom';
import { parseErrorMessages } from '../utils/errors'

import ReactGA from 'react-ga4';

const Register = () => {
    ReactGA.send({
        hitType: "pageview",
        page: '/register',
        title: 'Register'
    })

    const navigate = useNavigate();
    const location = useLocation();

    // Retrieve email from state if passed
    const initialEmail = location.state?.email || '';

    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const handleRegister = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        try {
            await axiosInstance.post('/auth/registration/', {
                email,
                password1: password,
                password2: confirmPassword,
                first_name: firstName,
                last_name: lastName,
                display_name: displayName,
            });
            setSuccessMessage('Registration successful! Please check your email to verify your account.');
        } catch (error) {
            console.error('Registration failed', error);
            setErrorMessage(parseErrorMessages(error.response?.data) || 'Registration failed');
        }
    };

    const handleResendVerificationEmail = async () => {
        try {
            await axiosInstance.post('/auth/registration/resend-email/', {
                email,
            });
            setSuccessMessage('Verification email resent! Please check your email.');
        } catch (error) {
            console.error('Resend verification email failed', error);
            setErrorMessage(parseErrorMessages(error.response?.data) || 'Failed to resend verification email');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-6 text-center">Register</h2>
                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
                        {errorMessage}
                    </div>
                )}
                {successMessage && (
                    <div className="bg-green-100 text-green-700 p-2 rounded mb-4">
                        {successMessage}
                    </div>
                )}
                <form onSubmit={handleRegister} className="space-y-4">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="text"
                        placeholder="Display Name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                        Register
                    </button>
                </form>
                <button
                    onClick={handleResendVerificationEmail}
                    className="w-full mt-4 bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
                >
                    Resend Verification Email
                </button>
            </div>
        </div>
    );
};

export default Register;