import axios from 'axios';
import { deleteAuthStorage } from "./utils/auth";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if error is due to unauthorized access (401)
        if (error.response && error.response.status === 401) {
            // If the request was to the token refresh endpoint, log out the user
            if (originalRequest.url.includes('/auth/token/refresh/')) {
                deleteAuthStorage();
                window.location.href = '/login';
                return Promise.reject(error);
            }

            // Prevent infinite loop by checking if we've already retried
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = localStorage.getItem('refresh_token');

                if (refreshToken) {
                    try {
                        // Create a new Axios instance without interceptors
                        const refreshAxiosInstance = axios.create({
                            baseURL: process.env.REACT_APP_API_URL,
                            headers: {
                                'Content-Type': 'application/json',
                                accept: 'application/json',
                            },
                        });

                        // Refresh the access token
                        const response = await refreshAxiosInstance.post('/auth/token/refresh/', { refresh: refreshToken });
                        localStorage.setItem('access_token', response.data.access);

                        // Update the Authorization header in the default Axios instance
                        axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + response.data.access;

                        // Update the Authorization header in the original request
                        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;

                        // Retry the original request with the new token
                        return axiosInstance(originalRequest);
                    } catch (refreshError) {
                        // If token refresh fails, log out the user
                        console.error('Error refreshing token:', refreshError);
                        deleteAuthStorage();
                        window.location.href = '/login';
                        return Promise.reject(refreshError);
                    }
                } else {
                    // No refresh token available, log out the user
                    console.log('Refresh token not available');
                    deleteAuthStorage();
                    window.location.href = '/login';
                    return Promise.reject(error);
                }
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;