import React from 'react';
import ReactGA from 'react-ga4';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import VerifyEmail from "./pages/VerifyEmail";
import PasswordReset from "./pages/PasswordReset";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
    ReactGA.initialize('G-Z6PEZK52HV');

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/confirm-email/:key" element={<VerifyEmail />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/auth/password/reset/:uidb64/:token/" element={<PasswordReset />} />
                    <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;