import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../axiosInstance';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { isAuthenticated } from '../utils/auth';
import { parseErrorMessages } from '../utils/errors';

import ReactGA from "react-ga4";

const Login = () => {
    ReactGA.send({
        hitType: "pageview",
        page: '/login',
        title: 'Login'
    })

    const navigate = useNavigate();
    const { setUser } = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleEmailLookup = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/auth/lookup/?email=${encodeURIComponent(email)}`);
            if (response.status === 200) {
                setEmailVerified(true);
                setErrorMessage('');
            }
        } catch (error) {
            console.error('Email lookup failed', error);
            setErrorMessage('User does not exist.')
            // navigate('/register', { state: { email } });
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!emailVerified) {
            handleEmailLookup();
            return;
        }
        try {
            const response = await axiosInstance.post('/auth/login/', {
                email,
                password,
            });
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            // Fetch user data
            const userResponse = await axiosInstance.get('/api/me/');
            setUser(userResponse.data);

            ReactGA.event({
                category: "Auth",
                action: "Login",
                label: userResponse.data.first_name
            });

            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed', error);
            setErrorMessage(parseErrorMessages(error.response?.data) || 'Login failed');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-6 text-center">Log in or sign up</h2>
                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
                        {errorMessage}
                    </div>
                )}
                <form onSubmit={handleLogin} className="space-y-4">
                    <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        disabled={loading || emailVerified}
                    />
                    {emailVerified && (
                        <>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                            >
                                Login
                            </button>
                        </>
                    )}
                    {!emailVerified && (
                        <button
                            type="button"
                            onClick={handleEmailLookup}
                            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                            disabled={loading || !email}
                        >
                            {loading ? 'Checking...' : 'Next'}
                        </button>
                    )}
                </form>
                <div className="text-center mt-4">
                    <a
                        href="#"
                        onClick={() => navigate('/auth/forgot-password')}
                        className="text-blue-500 hover:underline"
                    >
                        Forgot Password?
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;