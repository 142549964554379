export const parseErrorMessages = (errors) => {
    if (!errors) return '';
    let messages = [];
    for (let key in errors) {
        if (Array.isArray(errors[key])) {
            errors[key].forEach((message) => {
                messages.push(`${key}: ${message}`);
            });
        } else {
            messages.push(`${key}: ${errors[key]}`);
        }
    }
    return messages.join(' ');
};
