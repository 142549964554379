import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

import ReactGA from 'react-ga4';

const VerifyEmail = () => {
    ReactGA.send({
        hitType: "pageview",
        page: '/confirm-email/',
        title: 'Verify Email'
    })

    const { key } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const requestSent = useRef(false); // Use useRef to track if the request is made

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axiosInstance.get(`/auth/registration/account-confirm-email/${key}/`);
                setMessage(response.data.detail);
            } catch (error) {
                setMessage(error.response?.data?.detail || 'Verification failed');
            }
        };

        if (!requestSent.current) {
            verifyEmail();
            requestSent.current = true; // Set the flag to true after making the request
        }
    }, [key]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold mb-6 text-center">Email Verification</h2>
                {message && (
                    <div className={`p-2 rounded mb-4 ${message.includes('successfully') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                        {message}
                    </div>
                )}
                <button
                    onClick={() => navigate('/login')}
                    className="w-full mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                >
                    Go to Login
                </button>
            </div>
        </div>
    );
};

export default VerifyEmail;
